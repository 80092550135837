import React from 'react';
import {
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
import AccordionElementTick from 'components/AccordionElementTick/AccordionElementTick';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import './AccordionElement.scss';

const AccordionElementTicks = ({ ticks }) => {
  if (ticks) {
    return ticks
      .map(tick => (
        <AccordionElementTick
          key={tick.id}
          data={tick}
        />
      ));
  }

  return null;
};

const AccordionElement = ({ data }) => (
  <AccordionItem className="accordion-element">
    <AccordionItemHeading>
        <AccordionItemButton className="accordion-element__row">
          <div className="accordion-element__row-header">
            <div className="accordion-element__row-header__title">{data.title}</div>
            <div className="accordion-element__row-header__dash">&mdash;</div>
            <div className="accordion-element__row-header__subtitle">{data.subtitle}</div>
          </div>
          <div className="accordion-element__caret">&rsaquo;</div>
        </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel className="accordion-element__item-panel">
      <p className="accordion-element__description">{data.description}</p>
      <ul className="accordion-element__ticks">
        <AccordionElementTicks ticks={data.ticks} />
      </ul>
      <SeparatorWithGradient className="accordion-element__separator" />
    </AccordionItemPanel>
  </AccordionItem>
);

export default AccordionElement;
