import React from 'react';
import './AccordionElementTick.scss';

const AccordionElementTick = ({ data }) => (
  <li className="tick">
      <span className="tick__title">{data.title}</span>
      <div className="tick__description">{data.description}</div>
  </li>
);

export default AccordionElementTick;
