import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import AfterHeroSection from 'components/AfterHeroSection/AfterHeroSection';
import AccordionElementContainer from 'components/AccordionElementContainer/AccordionElementContainer';
import ScrollToTopButton from 'components/ScrollToTopButton';

const SmartBusinessPage = ({ data, location }) => {
  return (
    <Layout location={location} type="standard">
      <SEO title={data.smartBusinessYaml.title} />
      <Hero
        data={data.smartBusinessYaml.hero}
      />
      <AfterHeroSection data={data.smartBusinessYaml.afterHeroSection} />
      <AccordionElementContainer data={data.smartBusinessYaml.accordionElementContainer} />
      <ZipGateContainer location={location}/>
      <ScrollToTopButton />
    </Layout>
  );
};

export default SmartBusinessPage;

export const pageQuery = graphql`
  query SmartBusinessPageQuery {
    smartBusinessYaml {
      id
      title
      hero {
        size
        title
        subtitle
        disclaimer
        heroImage {
            childImageSharp {
              fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            } 
        }
        heroImagePosition
      }
      afterHeroSection {
        sectionName
        title
        subtitle
        description
      }

      accordionElementContainer {
        accordionContainerName
        accordionElements {
            title
            subtitle
            description
            ticks {
                title
                description
            }
        }
      }
    }
  }  
`;
